@use '@carbon/react/scss/spacing';

.skeleton {
  width: 100%;
  height: 100%;
}

.mapContainer {
  flex-grow: 1;
}

.overlay:hover {
  z-index: 9999;
}
