@use '@carbon/react/scss/spacing';
@use '@carbon/react/scss/type';

.nameCell {
  padding-inline-start: spacing.$spacing-08 !important;
}

.tdAccount {
  @include type.type-style('helper-text-01');
}

.editDriverColumn {
  align-items: center;
  display: inline-grid;
  grid-column-gap: spacing.$spacing-02;
  grid-auto-flow: column;
  white-space: nowrap;
}
