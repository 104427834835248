@use '@carbon/react/scss/theme';
@use '@carbon/react/scss/spacing';
@use '@carbon/react/scss/type';

.pageHeader {
  background: linear-gradient(
      to right,
      theme.$background-brand 0%,
      theme.$background 60%
    )
    left bottom no-repeat;
  background-size: 100% 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: spacing.$spacing-07;
  padding: spacing.$spacing-03;
  width: 100%;
}

.root {
  @include type.type-style('heading-02', true);
  display: table;
  margin-right: auto;
}

.header {
  > h3 {
    @include type.type-style('fluid-heading-06', true);
    display: inline;
  }
  > h4 {
    @include type.type-style('fluid-heading-03', true);
  }
}

.skeletonRoot {
  width: 150px !important;
}

.skeletonHeader {
  width: 500px;
}
