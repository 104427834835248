@use '@carbon/react/scss/breakpoint' as *;
@use '@carbon/react/scss/spacing';
@use '@carbon/react/scss/theme';
@use '@carbon/react/scss/type';

.fileUploadContainer {
  align-items: center;

  > div {
    > button {
      align-items: center;
      background-color: theme.$background-selected;
      block-size: spacing.$spacing-12 + spacing.$spacing-08;
      border: 3px dashed theme.$border-strong-01;
      color: theme.$text-primary;
      justify-content: center;
      max-inline-size: none;

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }
    }
  }

  > p {
    @include type.type-style('helper-text-02', true);

    align-self: self-end;
    margin-top: spacing.$spacing-03;

    &.hasError {
      color: theme.$text-error;
    }
  }
}
