@use '@carbon/react/scss/spacing';

.datePicker {
  padding-bottom: spacing.$spacing-08;
}

.charts {
  > div {
    padding-bottom: spacing.$spacing-08;
  }
}
