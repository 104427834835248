@use '@carbon/react/scss/breakpoint' as *;
@use '@carbon/react/scss/spacing';

.editColumn {
  text-align: right;
  margin-bottom: spacing.$spacing-05;
}

.column {
  margin-bottom: spacing.$spacing-05;
}
