@use '@carbon/react/scss/theme';
@use '@carbon/react/scss/spacing';

.paneBackground {
  background-color: theme.$layer-01;
  padding-top: spacing.$spacing-10;
  padding-left: spacing.$spacing-04;
  padding-right: spacing.$spacing-04;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.paneHeader {
  margin-bottom: spacing.$spacing-06;
  z-index: 1000; // or any value higher than your other elements
  position: absolute;
  backdrop-filter: blur(10px);
}

.paneContent {
  padding-top: spacing.$spacing-10;
  width: 100%;
  height: 100%;
}
