@use '@carbon/react/scss/spacing';
@use '@carbon/react/scss/theme';

.popoverContent {
  padding: spacing.$spacing-03;
}

.tag {
  outline: 1px solid theme.$background-inverse;
  outline-offset: -1px;
}
