@use '@carbon/react/scss/spacing';

.header {
  display: flex;
  margin-bottom: spacing.$spacing-02;

  > .avatar {
    margin-left: auto;

    > img {
      vertical-align: middle;
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }
  }
}
