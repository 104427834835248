@use '@carbon/react/scss/spacing';
@use '@carbon/react/scss/type';

.skeleton-padding {
  padding: spacing.$spacing-02;
}

.mobile-card {
  padding: spacing.$spacing-04 0;
}

.header {
  justify-content: space-between;

  > div:first-of-type {
    @include type.type-style('heading-02');
  }
}

.sub-header {
  @include type.type-style('heading-01');
}

.uploads {
  align-items: center;
}
