@use '@carbon/react/scss/type';
@use '@carbon/react/scss/spacing';
@use '@carbon/react/scss/theme';

.groupRow {
  background-color: theme.$layer-02;
  block-size: spacing.$spacing-08 !important;
}

.toolbarActionText {
  margin-right: spacing.$spacing-02;
}

.columnFilter {
  width: 10.5rem;

  & input::placeholder {
    @include type.type-style('heading-compact-01');

    color: theme.$text-primary;
  }
}
