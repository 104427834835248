@use '@carbon/react/scss/spacing';
@use '@carbon/react/scss/theme';

.buttonSet {
  > button {
    inline-size: 50% !important;
  }
}

.editDriverPopup {
  background-color: theme.$layer-01;
  padding: spacing.$spacing-04;
}
