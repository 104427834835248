@use '@carbon/react/scss/spacing';

.firmwareConfig {
  margin-bottom: spacing.$spacing-05;
}

.calibrationContainer {
  margin-bottom: spacing.$spacing-05;
}

.algorithm {
  margin-bottom: spacing.$spacing-06;
}
