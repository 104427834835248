@use '@carbon/react/scss/spacing';
@use '@carbon/react/scss/theme';

.serviceLocationRow {
  background-color: theme.$layer-02;
  block-size: spacing.$spacing-08 !important;
}

.containerSiteRow {
  block-size: spacing.$spacing-08 !important;
}

.containerSiteCell {
  padding-inline-start: spacing.$spacing-08 !important;
}
