.account-role-list {
  padding: 0;
  width: 100%;

  > div {
    margin-bottom: 1rem;

    &:last-of-type {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
}

$btn-size: 2.5rem;

@mixin btn {
  height: $btn-size;
  min-height: $btn-size;
  min-width: $btn-size;
  padding: 0.625rem;
  width: $btn-size;
}

.account-role-list-item-remove {
  padding-top: 1.25rem;

  > span > button {
    @include btn;
  }
}

.account-role-list-item-add {
  padding-top: 0;

  > span > button {
    @include btn;
  }
}
