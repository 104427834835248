@use '@carbon/react/scss/spacing';

.map-container {
  display: flex;
  height: 300px;
}

.header-grid {
  row-gap: spacing.$spacing-07;
}
