@use '@carbon/react/scss/spacing';
@use '@carbon/react/scss/colors';

.mobilePhone {
  > label {
    margin-block-end: spacing.$spacing-03;
  }

  .verify {
    display: flex;
    align-items: flex-start;
    > button {
      margin-left: spacing.$spacing-04;
    }
    > span {
      // toggle tip
      margin-left: spacing.$spacing-04;
      margin-top: spacing.$spacing-03;

      svg {
        fill: colors.$green-60;
      }
    }
  }
}
