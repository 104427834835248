@use '@carbon/react/scss/spacing';
@use '@carbon/react/scss/type';

.hint-container {
  margin: spacing.$spacing-06 0;

  > h5 {
    margin-bottom: spacing.$spacing-04;
  }

  p {
    @include type.type-style('heading-01', true);
    margin-bottom: spacing.$spacing-02;
  }
}
