.grid {
  padding-inline: 0;

  > :first-child {
    margin-left: 0;
  }

  > :last-child {
    margin-right: 0;
  }
}
