@use '@carbon/react/scss/type';

.title {
  @include type.type-style('heading-03', true);
}

.centered {
  text-align: center;
  width: 100%;
}
