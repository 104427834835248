.service-location-list {
  padding: 0;
  width: 100%;

  > div {
    margin-bottom: 1rem;

    &:last-of-type {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
}

.account-list-item-all {
  padding-top: 1rem;
}
