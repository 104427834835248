@use '@carbon/react/scss/breakpoint' as *;
@use '@carbon/react/scss/spacing';

.uploadContainer {
  margin-bottom: spacing.$spacing-08;

  @include breakpoint-up(md) {
    width: 80%;
  }

  @include breakpoint-up(lg) {
    width: 60%;
  }

  @include breakpoint-up(xlg) {
    width: 40%;
  }
}
